// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("stylesheets/application.css");
require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//

function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    document.selection.empty();
  }
}

window.copyText = function (containerId) {
  clearSelection();
  var container = document.getElementById(containerId);

  if (document.selection) {
    var range = document.body.createTextRange();
    range.moveToElementText();
    range.select().createTextRange(container);
  } else if (window.getSelection) {
    var range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);
  }

  document.execCommand("copy");
  clearSelection();
};
